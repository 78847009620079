const projectsData = [
  {
    id: 1,
    title: 'Learning Tracker APP',
    img: 'https://raw.githubusercontent.com/men32z/learning-tracker-app/development/docs/s2.png',
    description: 'A learning tracker app designed to help maintain your motivation to learn by measuring the time you spend studying. Built using Ruby and Rails and React',
    tags: [
      "React",
      "Redux",
      "Ruby on Rails",
      "HTML5",
      "SCSS",
      "PostgreSql",
      "Heroku",
    ],
    demo: 'https://men32z-learning-tracker-app.herokuapp.com/',
    repo: 'https://github.com/men32z/learning-tracker-app',
  },
  {
    id: 2,
    title: 'React Native Countdown Timer',
    img: 'https://raw.githubusercontent.com/men32z/react-native-countdown-timer/development/docs/scshot.png',
    description: 'The Countdown Timer mobile app was built using React Native with Expo. It\'s simply a countdown timer that allows users to add minutes and then start, pause, or accelerate the countdown.',
    tags: [
      "React Native",
      "Redux",
    ],
    demo: false,
    repo: 'https://github.com/men32z/react-native-countdown-timer',
  },
  {
    id: 3,
    title: 'React Calculator',
    img: 'https://raw.githubusercontent.com/men32z/react-calculator/development/docs/aaaaaa.png',
    description: 'This web calculator built with React and tested with Jest has all the basic functionalities of a normal calculator.',
    tags: [
      "React",
      "Jest",
      "SASS",
      "HTML5",
    ],
    demo: 'https://men32z-react-calculator.herokuapp.com/',
    repo: 'https://github.com/men32z/react-calculator',
  },
  {
    id: 4,
    title: 'Redux Bookstore',
    img: 'https://raw.githubusercontent.com/men32z/redux-bookstore/development/docs/bookstore.png',
    description: 'An app that allows users to save and remove books, set categories/genres, and list books with a filter. Built with React and Redux using local storage.',
    tags: [
      "React",
      "Redux",
      "SASS",
      "HTML5",
    ],
    demo: 'https://men32z-redux-bookstore.herokuapp.com/',
    repo: 'https://github.com/men32z/redux-bookstore/',
  },
  {
    id: 5,
    title: 'Facebook Clone',
    img: 'https://raw.githubusercontent.com/men32z/facebook-clone/development/docs/fb.png',
    description: 'A social media app designed to mimic some of Facebook\'s key features, such as the ability to create posts, send friend requests, and upload photos. Built using Ruby and Rails. ',
    tags: [
      "Ruby",
      "Rails",
      "Postgresql",
      "Heroku",
      "OmniAuth",
    ],
    demo: 'https://men32z-facebook-clone.herokuapp.com/',
    repo: 'https://github.com/men32z/facebook-clone',
  },
  {
    id: 6,
    title: 'NewsWeek Clone',
    img: 'https://raw.githubusercontent.com/men32z/newsweek-clone/feature-branch/images/screenshots/desktop_2.png',
    description: 'A replica of the news site Newsweek.com, built using Javascript and the Bootstrap framework.',
    tags: [
      "HTML5",
      "CSS3",
      "Bootstrap",
      "JavaScript",
      "JavaQuery",
      "Flexbox",
    ],
    demo: 'https://men32z.github.io/newsweek-clone/',
    repo: 'https://github.com/men32z/newsweek-clone',
  },
  {
    id: 7,
    title: 'My Own Grid-based Framework',
    img: 'https://raw.githubusercontent.com/men32z/grid-css-framework/grid-branch/assets/images/mobile.png',
    description: 'A grid-based framework (similar to bootstrap) that includes the basic functionality necessary to build a website.',
    tags: [
      "HTML5",
      "CSS3",
      "SASS",
      "Flexbox",
    ],
    demo: 'https://men32z.github.io/grid-css-framework/',
    repo: 'https://github.com/men32z/grid-css-framework',
  },
];

export default projectsData;